export const systemName = "PIIMS";
export const systemShortName = "PIIMS";

const env = "production";

export const imagesBaseUrl =
  env === "production"
    ? "https://api-piims.zigtech.net/uploads"
    : "http://localhost:9006/uploads";
const devBackendUrl = "http://localhost:9006/api/v1";
const prodBackendUrl = "https://api-piims.zigtech.net/api/v1";

export const backendUrl = env === "production" ? prodBackendUrl : devBackendUrl;
